import { Customer, TaggedCart, TaggedLineItem, TaggedOrder, TaggedProduct } from "./types"

const TAGGING_CLASS_NAMES = {
  page_type: "nosto_page_type",
  customer: "nosto_customer",
  search_term: "nosto_search_term",
  category: "nosto_category",
  product: "nosto_product",
  cart: "nosto_cart",
  order: "nosto_purchase_order"
}

function pageTypeToHtml(pageType: string) {
  return `<div class="${TAGGING_CLASS_NAMES.page_type}" style="display:none" translate="no">${pageType}</div>`
}

function customerToHtml(customer: Customer) {
  return `
    ${
      customer.group_id
        ? `<div class="nosto_variation" style="display: none;" translate="no">${customer.group_id}</div>`
        : ""
    }
    <div class="${TAGGING_CLASS_NAMES.customer}" style="display:none" translate="no">
      <span class="email">${customer.email}</span>
      <span class="customer_reference">${customer.customer_reference}</span>
      ${
        typeof customer.marketing_permission === "boolean"
          ? `<span class="marketing_permission">${customer.marketing_permission}</span>`
          : ""
      }
      ${customer.first_name ? `<span class="first_name">${customer.first_name}</span>` : ""}
      ${customer.last_name ? `<span class="last_name">${customer.last_name}</span>` : ""}
    </div>
  `
}

function searchTermToHtml(searchTerm: string) {
  const div = document.createElement("div")
  div.className = TAGGING_CLASS_NAMES.search_term
  div.style.display = "none"
  div.appendChild(document.createTextNode(searchTerm))
  return div
}

function categoryToHtml(category: string) {
  return `<div class="${TAGGING_CLASS_NAMES.category}" style="display:none" translate="no">${category}</div>`
}

function productToHtml(product: TaggedProduct) {
  return `
    <div class="${TAGGING_CLASS_NAMES.product}" style="display:none" translate="no">
      <span class="product_id">${product.product_id}</span>
    </div>
  `
}

function lineItemToHtml(item: TaggedLineItem) {
  if (!item.product_id) {
    return ""
  }

  return `
    <div class="line_item">
      <span class="product_id">${item.product_id}</span>
      ${item.sku_id ? `<span class="sku_id">${item.sku_id}</span>` : ""}
      <span class="quantity">${item.quantity}</span>
      <span class="name">${item.name}</span>
      <span class="unit_price">${item.unit_price}</span>
      <span class="price_currency_code">${item.price_currency_code}</span>
    </div>
  `
}

function cartToHtml(cart: TaggedCart) {
  return `
    <div class="${TAGGING_CLASS_NAMES.cart}" style="display:none" translate="no">
      ${cart.items.map(item => lineItemToHtml(item))}
    </div>
  `
}

function orderToHtml(order: TaggedOrder) {
  let paymentProviderTagging = ""
  if (order.payment_provider) {
    paymentProviderTagging = `<span class="payment_provider">${order.payment_provider}</span>`
  }
  return `
    <div class="${TAGGING_CLASS_NAMES.order}" style="display:none" translate="no">
      <span class="order_number">${order.order_number}</span>
      ${paymentProviderTagging}
      <div class="buyer">
        <span class="email">${order.buyer.email}</span>
        <span class="first_name">${order.buyer.first_name}</span>
        <span class="last_name">${order.buyer.last_name}</span>
      </div>
      <div class="purchased_items">
        ${order.items.map(item => lineItemToHtml(item))}
      </div>
    </div>
  `
}

function removeOldElements(taggingClassName: string) {
  Array.from(window.document.getElementsByClassName(taggingClassName)).forEach(element => {
    element.parentNode!.removeChild(element)
  })
}

function updateTagging(taggingClassName: string, taggingHtml: string) {
  removeOldElements(taggingClassName)
  window.document.body.insertAdjacentHTML("beforeend", taggingHtml)
}

function updateTaggingWithElement(taggingClassName: string, element: HTMLElement) {
  removeOldElements(taggingClassName)
  window.document.body.insertAdjacentElement("beforeend", element)
}

export function updatePageTypeTagging(pageType: string) {
  updateTagging(TAGGING_CLASS_NAMES.page_type, pageTypeToHtml(pageType))
}

export function updateCustomerTagging(customer: Customer) {
  updateTagging(TAGGING_CLASS_NAMES.customer, customerToHtml(customer))
}

export function updateSearchTermTagging(searchTerm: string) {
  updateTaggingWithElement(TAGGING_CLASS_NAMES.search_term, searchTermToHtml(searchTerm))
}

export function updateCategoryTagging(category: string) {
  updateTagging(TAGGING_CLASS_NAMES.category, categoryToHtml(category))
}

export function updateProductTagging(product: TaggedProduct) {
  updateTagging(TAGGING_CLASS_NAMES.product, productToHtml(product))
}

export function updateCartTagging(cart: TaggedCart) {
  updateTagging(TAGGING_CLASS_NAMES.cart, cartToHtml(cart))
}

export function updateOrderTagging(order: TaggedOrder) {
  updateTagging(TAGGING_CLASS_NAMES.order, orderToHtml(order))
}
