import { Customer } from "./types"

const CUSTOMER_EMAIL_STORAGE_KEY = "nosto_customer_email"
const CUSTOMER_REF_STORAGE_KEY = "nosto_customer_ref"
const CUSTOMER_MARKETING_KEY = "nosto_marketing_permission"
const CUSTOMER_FIRST_NAME_KEY = "nosto_first_name"
const CUSTOMER_LAST_NAME_KEY = "nosto_last_name"
const CUSTOMER_GROUP_ID_KEY = "nosto_group_id"

const storage = window.sessionStorage

export function loadCustomer(): Customer | undefined {
  const customerEmail = storage.getItem(CUSTOMER_EMAIL_STORAGE_KEY)
  const customerReference = storage.getItem(CUSTOMER_REF_STORAGE_KEY)
  if (customerEmail && customerReference) {
    return {
      customer_reference: customerReference,
      email: customerEmail,
      group_id: storage.getItem(CUSTOMER_GROUP_ID_KEY) ?? undefined,
      marketing_permission: storage.getItem(CUSTOMER_MARKETING_KEY) ?? undefined,
      first_name: storage.getItem(CUSTOMER_FIRST_NAME_KEY) ?? undefined,
      last_name: storage.getItem(CUSTOMER_LAST_NAME_KEY) ?? undefined
    }
  }

  return undefined
}

export function storeCustomer(customer: Customer) {
  storage.setItem(CUSTOMER_EMAIL_STORAGE_KEY, customer.email)
  storage.setItem(CUSTOMER_REF_STORAGE_KEY, customer.customer_reference)

  if (customer.group_id) {
    storage.setItem(CUSTOMER_GROUP_ID_KEY, customer.group_id)
  }

  if (typeof customer.marketing_permission === "boolean") {
    storage.setItem(CUSTOMER_MARKETING_KEY, customer.marketing_permission)
  }

  if (customer.first_name) {
    storage.setItem(CUSTOMER_FIRST_NAME_KEY, customer.first_name)
  }

  if (customer.last_name) {
    storage.setItem(CUSTOMER_LAST_NAME_KEY, customer.last_name)
  }
}
