import { type API } from "@nosto/client-lib"
import { SlotReference } from "./types"

const MAX_DEPTH = 20 // constant for the max height of the DOM to be traversed

/* Indirect Nosto API usage via the nostojs callback is needed, since the Shopify version might not be fully initialize */
export function apiFn(cb: (api: API) => void) {
  window.nostojs(cb)
}

/**
 * Traverses the parent DOM tree of the button to find the identifier of the first
 * element having the class `nosto_element`
 *
 * @param button - the button whose slot identifier is to be resolved
 * @returns the identifier of the element or false, if none was found
 */
function resolveContextSlotId(button: HTMLElement) {
  let height = 0
  let element = button
  while (element.parentElement) {
    height += 1
    element = element.parentElement
    if (element.classList.contains("nosto_element") && element.hasAttribute("id")) {
      return element.getAttribute("id")
    }
    if (height >= MAX_DEPTH) {
      return false
    }
  }
  return false
}

/**
 * Helper function to traverse the DOM tree, find the identifier of the slot that
 * was clicked and report a click event to Nosto.
 *
 * @param productId - the identifier of the product
 * @param element - the element that was clicked.
 */
export default function (productId: string, slotIdOrElement: SlotReference) {
  const slotId = typeof slotIdOrElement === "string" ? slotIdOrElement : resolveContextSlotId(slotIdOrElement)
  if (slotId) {
    apiFn(api => api.recommendedProductAddedToCart(productId, slotId))
  } else {
    return Promise.reject(new Error("Unable to resolve the recommendation slot identifier"))
  }
}
